import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useGoogleAnalytics } from "../../analytics/hooks/useGoogleAnalytics";
import { sdkSignRequest, sdkVerifyResponse } from "../api/post";
import { experiences } from "../schemas/experiences";

// Keep SWBCSDK instance outside of hook to prevent disruptions from re-render.
const Swivel = new window.SWBCSDK();
let SDK = null;
let experience;

const messages = {
  error: {
    message: "Unknown error has occurred, try again later111.",
    severity: "error"
  },
  close: {
    message: "You have exited bank account verification.",
    severity: "error"
  },
  default: {
    message: null,
    severity: null
  }
};

export const useSwivel = ({ token, initialization, payload, options }) => {
  const [alert, setAlert] = useState(messages.default);
  const [isLoading, setIsLoading] = useState(false);
  const [verifyReponse, setVerifyResponse] = useState(null);
  const [verifyError, setVerifyError] = useState(null);

  const { trackEvent, actions, categories } = useGoogleAnalytics();

  const validateResponse = (event) => {
    const blacklistedAba = options?.blacklistedAba;
    const valid = [];
    const blacklisted = [];
    const invalidAccountType = [];

    if (event.length) {
      event.forEach((element) => {
        const account = element?.data;

        const isRoutingNumberBlacklisted = [...blacklistedAba].includes(account?.routingNumber);
        const isAccountTypeInvalid = !["savings", "checking"].includes(account?.accountType?.toLowerCase());

        if (isRoutingNumberBlacklisted) {
          blacklisted.push(account);
        } else if (isAccountTypeInvalid) {
          invalidAccountType.push(account);
        } else {
          valid.push(account);
        }
      });
    }
    return { valid, blacklisted, invalidAccountType };
  };

  const sign = useMutation({
    mutationFn: sdkSignRequest,
    onSuccess: (response) => {
      if (response.data.signedMessage && SDK) {
        trackEvent(actions.CONNECT_ACH_ACCOUNT, categories.ATTEMPT_SUCCESS);
        SDK[experience.START](response.data.signedMessage);
        setIsLoading(false);
      }
    }
  });

  const verify = useMutation({
    mutationFn: sdkVerifyResponse,
    onSuccess: (response) => {
      const { data } = response;
      const error = data?.error;
      const success = data?.data;

      if (error) {
        if (error?.errorType === "USER_CANCELLED") {
          trackEvent(actions.CONNECT_ACH_ACCOUNT, categories.ATTEMPT_ABANDONED);
        } else {
          trackEvent(actions.CONNECT_ACH_ACCOUNT, categories.ATTEMPT_FAILURE);
        }
        setAlert({ ...messages.close, type: error?.errorType });
      }

      if (success) {
        trackEvent(actions.CONNECT_ACH_ACCOUNT, categories.ATTEMPT_SUCCESS);
        const validate = validateResponse(success);
        setVerifyResponse(validate);
      }

    },
    onError: (error) => {
      if (error?.response) {
        trackEvent(actions.CONNECT_ACH_ACCOUNT, categories.ATTEMPT_FAILURE);
        setVerifyError(error?.response?.data);
        setAlert(messages.error);
      }
    }
  });

  const initialize = (event = initialization) => {
    setIsLoading(true);
    experience = experiences[event.Experience];
    if (!SDK) {
      SDK = new Swivel[experience.TYPE](event);
      SDK[experience.HANDLER](responseHandler);
      SDK.setEventHandler(eventHandler);
    }
  };

  const openFlow = (event) => {
    sign.mutate({ event, token });
  };

  const responseHandler = (event) => {
    verify.mutate({ event, token });
  };

  const eventHandler = (event) => {
    if (event.type === "InitializationAck") {
      openFlow(payload);
    }
  };

  const closeFlow = () => {
    if (SDK) {
      SDK[experience.CLOSE_FLOW]();
      SDK = null;
    }

    setAlert(messages.default);
    setVerifyError(null);
    setVerifyResponse(null);
    setIsLoading(false);

    sign.reset();
    verify.reset();
  };

  return {
    initialize,
    closeFlow,
    openFlow,
    isLoading,
    verifyError,
    verifyReponse,
    alert
  };
};

import React, { useState, createContext } from "react";

export const SwivelContext = createContext({
  isLoading: false,
  previousRoute: "",
  setIsLoading: () => { },
  setPreviousRoute: () => { },
  embedded: false
});

export const SwivelContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [previousRoute, setPreviousRoute] = useState("");
  const embedded = !!window?.location?.ancestorOrigins?.length;

  return (
    <SwivelContext.Provider value={{
      isLoading,
      previousRoute,
      setIsLoading,
      setPreviousRoute,
      embedded
    }}>
      {children}
    </SwivelContext.Provider>
  );
};

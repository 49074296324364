export const paymentAmountInputMask = (event, setValue) => {
  const { value } = event.target;
  const decimalIndex = value.indexOf(".");

  if (!value.includes(".")) {
    setValue("paymentAmount", `${value}.00`);
  } else if ((value.slice(decimalIndex)).length === 1) {
    setValue("paymentAmount", `${value}00`);
  } else if ((value.slice(decimalIndex)).length === 2) {
    setValue("paymentAmount", `${value}0`);
  }
};
